<template>
  <validation-observer ref="validationObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>
        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="form.lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="fetchByLocale" />
        </b-form-group>

        <b-form-group label="Tytuł zdjęcia" label-for="v-image-title">
          <validation-provider name="Tytuł zdjęcia" rules="required|max:150" v-slot="{ errors }">
            <b-form-input id="v-image-title" placeholder="Tytuł zdjęcia" v-model="form.title" :disabled="!form.lang" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Zdjęcie nagłówkowe" label-for="v-image">
          <b-button @click="toggleShowModal('title_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Logo" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.title_image" type="hidden" name="Logo" v-model="files.title_image.file" />
            <input v-else type="hidden" name="Logo" v-model="files.title_image" />

            <ImageSelector
              v-if="showImagesModal.title_image"
              :files="filesArray"
              :show-modal="showImagesModal.title_image"
              @closeModal="toggleShowModal('title_image')"
              @submit="(fileList) => handleFile(fileList[0], 'title_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.title_image"
            :image="files.title_image"
            @deleteImage="() => handleDelete('title_image')" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mt-3 mb-2">Treść</h4>
        <b-form-group label="Treść" label-for="v-image">
          <validation-provider #default="{ errors }" name="Treść" rules="required">
            <quill-editor v-model="form.value" :options="editorOption" :disabled="!form.lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col class="mt-3 mb-2">
        <b-form-group label="Zdjęcie do cytatów - lewe" label-for="v-left-image">
          <b-button @click="toggleShowModal('left_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie lewe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.left_image" type="hidden" name="Zdjęcie lewe" v-model="files.left_image.file" />
            <input v-else type="hidden" name="Zdjęcie lewe" v-model="files.left_image" />

            <ImageSelector
              v-if="showImagesModal.left_image"
              :files="filesArray"
              :show-modal="showImagesModal.left_image"
              @closeModal="toggleShowModal('left_image')"
              @submit="(fileList) => handleFile(fileList[0], 'left_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.left_image"
            :image="files.left_image"
            @deleteImage="() => handleDelete('left_image')" />
        </b-form-group>
      </b-col>

      <b-col class="mt-3 mb-2">
        <b-form-group label="Zdjęcie do cytatów - prawe" label-for="v-right-image">
          <b-button @click="toggleShowModal('right_image')" :disabled="!form.lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie prawe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.right_image" type="hidden" name="Zdjęcie prawe" v-model="files.right_image.file" />
            <input v-else type="hidden" name="Zdjęcie prawe" v-model="files.right_image" />

            <ImageSelector
              v-if="showImagesModal.right_image"
              :files="filesArray"
              :show-modal="showImagesModal.right_image"
              @closeModal="toggleShowModal('right_image')"
              @submit="(fileList) => handleFile(fileList[0], 'right_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.right_image"
            :image="files.right_image"
            @deleteImage="() => handleDelete('right_image')" />
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          :disabled="!this.form.lang.lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin.vue';
import { toolbarSettings } from '@/constants/quill-editor';
import { getLanguages } from '@/constants/languages';

import ImageSelector from '@/components/shared/ImageSelector.vue';
import ImagePreview from '@/components/shared/ImagePreview.vue';

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  mixins: [FileUploadMixin, NotificationMixin],
  components: {
    quillEditor,
    ImageSelector,
    ImagePreview,
  },

  data() {
    return {
      form: this.page,
      languages: getLanguages(),
      editorOption: {
        modules: {
          toolbar: toolbarSettings,
        },
      },
    };
  },

  created() {
    this.form.lang = {
      lang: 'pl',
      value: 'Polski',
    };
    this.setFileUploadData(['title_image', 'left_image', 'right_image'], ['title_image', 'left_image', 'right_image']);
    this.fetchByLocale();
  },

  watch: {
    async page(newValue) {
      if (this.form.value === newValue) return;

      await this.setImage(this.page.title_image, 'title_image');
      await this.setImage(this.page.left_image, 'left_image');
      await this.setImage(this.page.right_image, 'right_image');

      const lang = this.form.lang;
      this.form = { ...newValue };
      this.form.lang = lang;
    },
  },

  methods: {
    onSubmit() {
      const data = {
        ...this.form,
        title_image: this.files.title_image,
        left_image: this.files.left_image,
        right_image: this.files.right_image,
        lang: this.form.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.validationObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    fetchByLocale() {
      this.$emit('fetchByLocale', this.form.lang.lang);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
