<template>
  <b-card>
    <form-component :page="aboutUsHomepage" @onSubmit="onSubmit" @fetchByLocale="fetchAboutUsHomepage" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/pages/about-us-homepage/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, submit } from '@/http/about-us-homepage';
import { createBasicPageForm } from '@/constants/utils/form';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      aboutUsHomepage: {
        lang: '',
        title: '',
        value: '',
        title_image: null,
        left_image: null,
        right_image: null,
      },
    };
  },

  methods: {
    fetchAboutUsHomepage(lang = 'pl') {
      show(lang)
        .then(async ({ data: { data } }) => {
          if (!data) return;

          this.aboutUsHomepage = data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił błąd podczas pobierania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
    onSubmit(data) {
      const form = createBasicPageForm(data);
      console.log(data);

      form.append('left_image', data.left_image.file);
      form.append('right_image', data.right_image.file);

      submit(form)
        .then(() => {
          this.showSuccessNotification('Dane zapisane', 'Strona została zapisana.');
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z zapisaniem danych',
            'Wystąpił błąd podczas zapisywania danych. Skontaktuj się ze swoim developerem.',
          );
        });
    },
  },
};
</script>
